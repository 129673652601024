<template>
    <div>
        <v-container>
            <v-card style="background-color: #F5F5F5" elevation="3">
              <div class="mt-2 mb-5 text-h5 text-sm-h5 text-md-h4 text-lg-h4 ma-3 text-xl-h4 text-center">
                <span v-if="isNew">Nuevo</span>
                <span v-if="!isNew">Editar</span>
                pedido
              </div>

              <!-- FORMULARIO PEDIDO -->
              <v-row class="mt-2 mx-2">
                <v-col class="mb-n7" cols="6" sm="4" md="3" lg="3" xl="2">
                  <DateFind :readonly="soloLectura" label="Fecha" v-model="doc.DocFec"></DateFind>
                </v-col>

                <v-col class="mb-n7" cols="6" sm="4" md="3" lg="3" xl="2">
                  <DateFind :readonly="soloLectura" label="Fecha de entrega" v-model="doc.FecFin"></DateFind>
                </v-col>

                <v-col cols="12" sm="7" md="5" lg="5" xl="4">
                  <PerFind :readonly="soloLectura" v-show="false" :opeId="1" class="mb-n7" ref="perCtrl" v-model="doc.Per" label="Cliente" @change="validate"></PerFind>
                  <!-- :perCatIdAnyLevel="perCatIdEmpleados" perCatIdEmpleados = 101 -->
                </v-col>
              </v-row>

              <v-row class="mt-1">
                <!-- LINEAS -->
                <v-col class="px-7" cols="12" sm="12" md="12" lg="12" xl="12">
                  
                  <v-row no-gutters>
                    <!-- Botón añadir linea -->
                    <v-btn
                    :loading="loading"
                    :disabled="editNewRow || soloLectura"
                    @click="onBtnNuevaLinea"
                    color="primary"
                    small
                    class="mb-2 mr-2">
                    Nueva línea
                    </v-btn>
                  
                    <v-btn
                    :loading="loading"
                    :disabled="soloLectura || doc == null || doc.Per == null || doc.Per.PerId == null"
                    @click="onBtnRegularArt"
                    color="primary"
                    small
                    class="mb-2 mr-2">
                    Artículos habituales
                    </v-btn>

                    <v-btn
                    :loading="loading"
                    :disabled="soloLectura || doc == null || doc.Per == null || doc.Per.PerId == null"
                    @click="onBtnAllArt"
                    color="primary"
                    small
                    class="mb-2">
                    Todos los artículos
                    </v-btn>

                    <v-spacer></v-spacer>
                    
                    <v-switch class="mt-0 pt-0 pb-1"
                      v-model="filtrarCantidad"
                      :label="'Filtrar con cantidad'"
                      :hide-details="true"
                      @change="mapLineas"
                    ></v-switch>
                  </v-row>


                    <!-- :server-items-length="lineasTotalDataRows" -->
                  <v-data-table
                    :headers="lineasHeaders"
                    :items="lineasDataRows"
                    :options.sync="lineasOptions"
                    :footer-props="{itemsPerPageOptions: [15, 30, 50, 100]}"
                    :loading="loading"
                    @click:row="onClickRowEditarLinea"
                    style="overflow: scroll"
                    mobile-breakpoint="0"
                    class="elevation-1"
                    dense
                    >
                      <!-- eslint-disable-next-line vue/valid-v-slot -->
                      <template v-slot:item.DocArtArtNom="{item}">
                        <div v-if="item.edit && !soloLectura" class="my-1">
                          <ArtFind class="mb-n7" v-model="selectedRow.Art" :opeId="2" :perIdTar="doc.Per.PerId" label="Artículo"></ArtFind>
                        </div>
                        <template v-else>
                          {{item.DocArtArtNom}}
                        </template>
                      </template>
                      <!-- eslint-disable-next-line vue/valid-v-slot -->
                      <template v-slot:item.DocArtCant="{item}">
                        <div v-if="item.edit && !soloLectura" class="my-1">
                          <v-text-field
                            class="mb-n7"
                            outlined
                            dense
                            type="number"
                            min="0"
                            v-model="selectedRow.DocArtCant"
                            label="Cantidad"
                            @blur="onBlurFixCantidad()"
                          ></v-text-field>
                        </div>
                        <template v-else>
                          {{item.DocArtCant.toFixed(2)}}
                        </template>
                      </template>
                      <!-- eslint-disable-next-line vue/valid-v-slot -->
                      <template v-slot:item.Opt="{ item }">
                        <div v-if="item.edit && !soloLectura" style="min-width: 250px">
                          <v-btn
                            :loading="loading"
                            @click.stop="onBtnSaveEditLinea"
                            color="primary"
                            class="mt-2 mb-4 mr-2"
                            small>
                            Aceptar
                            </v-btn>
                            
                            <v-btn
                            @click.stop="onBtnCancelEditLinea"
                            class="mt-2 mb-4 mr-2"
                            small>
                            Cancelar
                            </v-btn>
                        </div>
                        <template v-else>
                          <v-btn v-if="!soloLectura"
                            color="error"
                            class="mr-2 my-1"
                            small
                            @click.stop="onBtnShowLineaDeleteModal(item)"
                          >
                            <v-icon>
                              mdi-delete
                            </v-icon>
                          </v-btn>
                        </template>
                      </template>
                      <!-- NUEVA LINEA FORMULARIO -->
                      <template v-if="editNewRow && !soloLectura" slot="body.append">
                        <tr class="new-row">
                            <td>
                              <ArtFind class="mb-n7" v-model="newDocArtRow.Art" :opeId="2" :perIdTar="doc.Per.PerId" label="Artículo"></ArtFind>
                            </td>
                            <td>
                              <v-text-field
                                class="mb-n7"
                                outlined
                                dense
                                type="number"
                                min="0"
                                v-model="newDocArtRow.DocArtCant"
                                label="Cantidad"
                                @blur="onBlurFixCantidad"
                              ></v-text-field>
                            </td>
                            <td style="min-width: 250px"  align ="right">
                              <v-btn
                                :loading="loading"
                                @click="onBtnSaveLinea"
                                color="primary"
                                class="mt-2 mb-4 mr-2"
                                small>
                                Aceptar
                                </v-btn>
                                
                                <v-btn
                                @click="onBtnCancelLinea"
                                class="mt-2 mb-4 mr-2"
                                small>
                                Cancelar
                                </v-btn>
                            </td>
                        </tr>
                      </template>
                  </v-data-table>
                </v-col>

              </v-row>

              <!-- ACEPTAR / ENVIAR / CANCELAR / BORRAR -->
              <v-row justify="center">
                  <v-btn v-if="!soloLectura"
                  :loading="loading"
                  :disabled="!isValid"
                  @click="onBtnSave"
                  color="primary"
                  class="mt-2 mb-4 mr-2"
                  style="width:100px">
                  Aceptar
                  </v-btn>

                  <v-btn v-if="doc.DocId != null && !soloLectura"
                  :loading="loading"
                  @click="onBtnEnviar"
                  color="primary"
                  class="mt-2 mb-4 mr-2"
                  style="width:100px">
                  Enviar
                  </v-btn>
                  
                  <v-btn 
                  class="mt-2 mb-4 mr-2"
                  style="width:100px"
                  @click="goToList">
                    <template v-if="lineasDataRows.length == 0">Cancelar</template>
                    <template v-else>Salir</template>
                  </v-btn>

                  <v-btn v-if="!isNew && !soloLectura"
                  color="error"
                  class="mt-2 mb-4"
                  style="width:100px"
                  @click="onBtnShowDeleteModal()"
                  >
                  Borrar
                  </v-btn>

                  <!-- <v-btn class="mt-2 mb-4 ml-3"
                  @click="soloLectura = !soloLectura"
                  >
                  test readonly
                  </v-btn> -->
              </v-row>
            </v-card>
        </v-container>

        <!-- Modales de borrado -->
        <!-- Borrar Pedido -->
        <v-dialog
          v-model="showDeleteDialog"
          transition="dialog-bottom-transition"
          persistent
          width="500"
        >
          <v-card>
            <v-card-title class="text-h5 grey lighten-2">
              BORRAR PEDIDO
            </v-card-title>

            <v-card-text class="mt-2">
              ¿Desea borrar el pedido? Esta acción no se podrá deshacer.
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                :loading="loading"
                color="primary"
                @click="onBtnDelete"
              >
                Aceptar
              </v-btn>
              <v-btn
                @click="onBtnCancelDelete"
              >
                Cancelar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <!-- Modal borrar línea -->
        <v-dialog
          v-model="showDeleteLineaDialog"
          transition="dialog-bottom-transition"
          persistent
          width="500"
        >
          <v-card>
            <v-card-title class="text-h5 grey lighten-2">
              BORRAR LÍNEA
            </v-card-title>

            <v-card-text class="mt-2">
              ¿Desea borrar la línea? Esta acción no se podrá deshacer.
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                :loading="loading"
                color="primary"
                @click="onBtnLineaDelete"
              >
                Aceptar
              </v-btn>
              <v-btn
                @click="onBtnCancelLineaDelete"
              >
                Cancelar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <!-- Modal error -->
        <v-dialog
          v-model="showErrorDialog"
          transition="dialog-bottom-transition"
          persistent
          width="500"
        >
          <v-card>
            <v-card-title class="text-h5 grey lighten-2">
              ERROR
            </v-card-title>

            <v-card-text class="mt-2">
              {{errorMsg}}
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                @click="onBtnCloseError"
              >
                Cerrar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

    </div>
</template>

<script>
import axios from "axios";
import ArtFind from "../components/ArtFind.vue";
import DateFind from "../components/DateFind.vue";
import PerFind from "../components/PerFind.vue";
import { mapState } from "vuex";
export default ({
    components: {
        ArtFind, DateFind, PerFind
    },
    computed: {
        ...mapState(['empId', 'perId', 'urlRaiz', 'dirId', 'per'])
    },
    data () {
        return {
            loading: false,
            isNew: true,
            // enviado: false,
            soloLectura: false,
            isValid: false,
            lineEditAction: 'NONE',
            showDeleteDialog: false,
            showDeleteLineaDialog: false,
            showErrorDialog: false,
            errorMsg: '',
            doc: {
                DocId: null,
                DocFec: null,
                FecFin: null,
                Per: {}
            },
            filtrarCantidad: false,
            apiLineas: [],
            // lineasTotalDataRows: 0,
            lineasDataRows: [],
            lineasOptions: {itemsPerPage: 30},
            lineasHeaders: [
                { text: 'Artículo', width: 300, sortable: false, value: 'DocArtArtNom' },
                { text: 'Cantidad', width: 100, sortable: false, value: 'DocArtCant' },
                { text: '', width: 50, sortable: false, value: 'Opt', align: 'end' }
            ],
            selectedRow: null,
            editNewRow: false,
            newDocArtRow: {},
            selectedDocArtDelete: null
        }
    },
    watch: {
      doc: {
        handler () {
            this.validate();
        },
        deep: true,
      }
    },
    mounted() {
      this.doc.Per = this.per;
      this.isNew = this.$route.meta.isNew;
      
      if(!this.isNew) {
          this.doc.DocId = this.$route.params.id;
          this.loadData();
      }
      else {
        var hoy = new Date();

        var day2Char = hoy.getDate().toString();
        var month2Char = (hoy.getMonth() + 1).toString();
        if (day2Char.length == 1) {day2Char = '0' + day2Char}
        if (month2Char.length == 1) {month2Char = '0' + month2Char}

        this.doc.DocFec = hoy.getFullYear() + '-' + month2Char + '-' + day2Char;

        var daysToAdd = 1;

        var fecFin = new Date(hoy.setDate(hoy.getDate() + daysToAdd))

        day2Char = fecFin.getDate().toString();
        month2Char = (fecFin.getMonth() + 1).toString();
        if (day2Char.length == 1) {day2Char = '0' + day2Char}
        if (month2Char.length == 1) {month2Char = '0' + month2Char}

        var FecFin = fecFin.getFullYear() + '-' + month2Char + '-' + day2Char; 
        this.doc.FecFin = FecFin;
      }
    },
    methods: {
      goToList() {
        this.$router.push('/pedidos-venta');
      },
      getDataFromApi() {
        this.loading = true;
        return new Promise((resolve) => {
          const controllerParameters = {
            Action: 'GET_DOC',
            DocId: this.doc.DocId
          };       
          const AuthToken = localStorage.getItem('token');
          axios({ method: "POST", "url": this.urlRaiz + "/api/doc", "data": JSON.stringify(controllerParameters), "headers": {"content-type": "application/json", "Authorization": AuthToken } })
          .then(result => {
            const item = result.data.Doc;
            const isDocEnv = result.data.IsDocEnv;

            setTimeout(() => {
                this.loading = false;
                resolve({
                  item,
                  isDocEnv
                })
            }, 1000)
          }).catch(error => {
            if (error != null) {
                this.loading = false;
                alert('Error al cargar el pedido.');
                this.goToList();
            }
          });
        })
      },
      postDataToApi() {
        this.loading = true;

        let lines;

        switch(this.lineEditAction) {
          case 'NEW': 
            lines = this.newDocArtRow;
            break;
          case 'EDIT':
            lines = this.selectedRow;
            break;
          case 'DELETE':
            lines = this.selectedDocArtDelete;
            break;
          default:
            lines = null;
            break;
        }
        
        return new Promise((resolve) => {
          const controllerParameters = {
            Action: 'SAVE_ENTITY',
            EmpId: this.empId,
            DirId: this.dirId,
            DocId: this.doc.DocId,
            DocFec: this.doc.DocFec,
            FecFin: this.doc.FecFin,
            PerId: this.doc.Per != null ? this.doc.Per.PerId : null,
            Lineas: lines,
            LineEditAction: this.lineEditAction
          };       
          const AuthToken = localStorage.getItem('token');
          axios({ method: "POST", "url": this.urlRaiz + "/api/doc", "data": JSON.stringify(controllerParameters), "headers": {"content-type": "application/json", "Authorization": AuthToken } })
          .then(result => {
            const item = result.data;

            setTimeout(() => {
                this.loading = false;
                resolve({
                  item
                })
            }, 1000)
          }).catch(error => {
            if (error != null) {
                this.loading = false;
                alert('Error al guardar el pedido.');
            }
          });
        })
      },
      deleteDataFromApi() {
        this.loading = true;
        return new Promise((resolve) => {
          const controllerParameters = {
            Action: 'DEL_ENTITY',
            DocId: this.doc.DocId
          };       
          const AuthToken = localStorage.getItem('token');
          axios({ method: "POST", "url": this.urlRaiz + "/api/doc", "data": JSON.stringify(controllerParameters), "headers": {"content-type": "application/json", "Authorization": AuthToken } })
          .then(result => {
            const success = result.data;

            setTimeout(() => {
                this.loading = false;
                resolve({
                  success
                })
            }, 1000)
          }).catch(error => {
            if (error != null) {
                this.loading = false;
                alert('Error al borrar el pedido.');
            }
          });
        })
      },
      sendDocFromApi() {
        this.loading = true;

        return new Promise((resolve) => {
          const controllerParameters = {
          Action: 'SEND_DOC',
          DocId: this.doc.DocId,
          EmpId: this.empId,
          DirId: this.dirId,
          PerId: this.perId,
          Desarrollo: true
          };       
          const AuthToken = localStorage.getItem('token');
          axios({ method: "POST", "url": this.urlRaiz + "/api/doc", "data": JSON.stringify(controllerParameters), "headers": {"content-type": "application/json", "Authorization": AuthToken } })
          .then(result => {
          const item = result.data;

          setTimeout(() => {
            this.loading = false
            resolve({
              item
            })
          }, 1000)
          }).catch(error => {
            if (error != null) {
                this.loading = false;
                alert('Error al enviar el pedido.');
            }
          });
        })
      },
      loadData() {
        this.getDataFromApi()
          .then(data => {
            if(data.item == null) {
              alert('Pedido no encontrado.');
              this.goToList();
              return;
            }
            this.mapData(data);
            // this.enviado = data.isDocEnv;
          });
      },
      saveData() {
        this.postDataToApi().then(data => {
          if(data.item.DocId != null) {
              if(this.lineEditAction == 'NONE') {
                this.goToList();
              } else {
                switch(this.lineEditAction) {
                  case 'NEW': 
                    this.editNewRow = false;
                    break;
                  case 'DELETE':
                    this.showDeleteLineaDialog = false;
                    break;
                }
                this.mapData(data);
              }
          }
          else {
              this.errorMsg = data.item.Error;
              this.showErrorDialog = true;
          }
          
          this.lineEditAction = 'NONE';  
        });
      },
      mapData(data) {
        this.doc = data.item;
        const date = new Date(data.item.DocFec);
        this.doc.DocFec = date.getFullYear() + '-' + String(date.getMonth() + 1).padStart(2, '0')  + '-' + String(date.getDate()).padStart(2, '0');

        if(data.item.FecFin != null) {
          const date2 = new Date(data.item.FecFin);
          this.doc.FecFin = date2.getFullYear() + '-' + String(date2.getMonth() + 1).padStart(2, '0')  + '-' + String(date2.getDate()).padStart(2, '0');
        }

        // this.doc.Per = {
        //     PerId: this.doc.ProvId,
        //     PerNom: this.doc.DocProvNom
        // }

        this.lineasDataRows = [];
        this.apiLineas = [];
        data.item.DocArt.forEach(i => {
          this.apiLineas.push({
            DocArtId: i.DocArtId,
            ArtId: i.ArtId,
            DocArtArtNom: i.DocArtArtNom,
            DocArtCant: i.DocArtCant
          });
        });
        this.mapLineas();
      },
      mapLineas() {
        // Cancelamos la edición si se está editando alguna linea
        if(this.selectedRow != null) {
          this.apiLineas
          .filter(f => f.edit).forEach(i => {
            i.edit = false;
          });
          this.selectedRow = null;
        }

        this.apiLineas.sort((a, b) => {
          if (a.DocArtArtNom < b.DocArtArtNom) {
            return -1;
          }
          if (a.DocArtArtNom > b.DocArtArtNom) {
            return 1;
          }
          return 0;
        });

        if(this.filtrarCantidad) {
          this.lineasDataRows = this.apiLineas.filter(f => f.DocArtCant != 0);
        } else {
          this.lineasDataRows = this.apiLineas;
        }
      },
      // Crear o editar una linea del pedido
      onClickRowEditarLinea(item) {
        if(this.loading || item.edit || this.soloLectura) { return; }
        
        if(this.selectedRow != null) {
          this.lineasDataRows
          .filter(f => f.edit).forEach(i => {
            i.edit = false;
          });
        }

        item.edit = true;
        
        this.selectedRow = {
          DocArtId: item.DocArtId,
          DocArtCant: item.DocArtCant,
          Art: {
            ArtId: item.ArtId,
            ArtNom: item.DocArtArtNom
          }
        };
        this.$forceUpdate();
      },
      onBtnNuevaLinea() {
        if(this.soloLectura) { return; }
        this.editNewRow = true;
        this.newDocArtRow = {};
      },
      // Confirmación guardado
      onBtnSave() {
          if(this.soloLectura) { return; }
          this.validate();
          if(!this.isValid) { return; }
          this.saveData();
      },
      onBtnSaveLinea() {
          if(this.soloLectura) { return; }
          this.newDocArtRow.ArtId = this.newDocArtRow.Art != null ? this.newDocArtRow.Art.ArtId : 0;
          this.newDocArtRow.DocArtArtNom =  this.newDocArtRow.Art != null ?  this.newDocArtRow.Art.ArtNom : null;
          this.lineEditAction = 'NEW';
          this.saveData();
      },
      onBtnSaveEditLinea () {
        if(this.soloLectura) { return; }
        this.lineEditAction = 'EDIT';
        this.selectedRow.ArtId =  this.selectedRow.Art != null ? this.selectedRow.Art.ArtId : 0;
        this.selectedRow.DocArtArtNom = this.selectedRow.Art != null ? this.selectedRow.Art.ArtNom : null;
        this.saveData();
      },
      onBtnRegularArt () {
        if(this.soloLectura) { return; }
        this.lineEditAction = 'REGULAR_LIST';
        this.saveData();
      },
      onBtnAllArt () {
        if(this.soloLectura) { return; }
        this.lineEditAction = 'ALL_ARTS';
        this.saveData();
      },
      // Enviar pedido
      onBtnEnviar() {
        if(this.soloLectura) { return; }
        this.sendDocFromApi()
        .then(data => {
          if(data.item.Error != null) { 
            this.errorMsg = data.item.Error;
            this.showErrorDialog = true;
          }
          else if(!data.item) { 
            this.errorMsg = 'Pedido no enviado';
            this.showErrorDialog = true;
          }
          else {
            // Marcar como enviado
            // this.enviado = true;
            this.goToList();
          }
        });
      },
      // Confirmación de borrado
      onBtnDelete() {
        if(this.soloLectura) { return; }
        this.deleteDataFromApi().then(data => {
          this.showDeleteDialog = false;
          if (data.success.Success) {
            this.goToList();
          } else {
              this.errorMsg = data.success.Error;
              this.showErrorDialog = true;
          }
        });
      },
      onBtnLineaDelete() {
        if(this.soloLectura) { return; }
        this.lineEditAction = 'DELETE';
        this.saveData();
      },
      // Cancelar edición de lineas
      onBtnCancelLinea() {
        this.editNewRow = false;
      },
      onBtnCancelEditLinea() {
        this.lineasDataRows
        .filter(f => f.edit).forEach(i => {
          i.edit = false;
        });
        this.selectedRow = null;
        this.$forceUpdate();
      },
      // Mostrar modales
      onBtnShowDeleteModal() {
        this.showDeleteDialog = true;
      },
      onBtnShowLineaDeleteModal(item) {
        this.selectedDocArtDelete = item;
        this.showDeleteLineaDialog = true;
      },
      // Cierre modales
      onBtnCancelDelete() {
        this.showDeleteDialog = false;
      },
      onBtnCancelLineaDelete() {
        this.selectedDocArtDelete = null;
        this.showDeleteLineaDialog = false;
      },
      onBtnCloseError() {
        this.errorMsg = '',
        this.showErrorDialog = false;
      },
      // Eventos y validaciones
      onBlurFixCantidad() {
          if(this.newDocArtRow != null) { this.newDocArtRow.DocArtCant = Number(this.newDocArtRow.DocArtCant).toFixed(2); }
          if(this.selectedRow != null) { this.selectedRow.DocArtCant = Number(this.selectedRow.DocArtCant).toFixed(2); }
      },
      validate() {
          if (this.doc == null) {
              this.isValid = false;
              return;
          }
          this.isValid = this.doc.DocFec != null && this.doc.Per != null;
      } 
    }
})
</script>
