<template>
  <v-autocomplete
      v-model="value"
      cache-items
      dense
      hide-selected
      :items="itemsList"
      :search-input.sync="searchItem"
      open-on-clear
      item-value="ArtId"
      item-text="ArtNom"
      outlined
      no-data-text="Introduzca texto a buscar"
      :label="label"
      :readonly="readonly"
      return-object
      ref="ArtFindCtrl"
      :clearable="!readonly"
      :menu-props="{'open-on-click': true}"
      @click="clickEvt"
      @click:append="clickAppendEvt"
      @input="inputEvt"
      @change="changeEvt"
      @blur="blurEvt"
  ></v-autocomplete>
</template>

<script>
  import axios from "axios";
  import { mapState } from "vuex";
  export default {
    computed:{
        ...mapState(['empId', 'perId', 'urlRaiz'])
    },
    data: () => ({
      // selectedValue: [],
      itemsList: [],
      searchItem: null,
    }),
    // model: {
    //   prop: 'selectedValue'
    // },
    // props: ['value', 'opeId', 'perIdTar', 'readonly', 'label'],
    props: { 
      value: { type: Object }, 
      opeId: { type: Number }, 
      perIdTar: { type: Number }, 
      readonly: { type: Boolean }, 
      label: { type: String, default: 'Artículo' },
      selectOnReadonly: { type: Boolean },
      includeArtCat: { type: Boolean, default: false }
    },
    watch: {
      searchItem(val) {
        this.loadItems(val);
      },
      opeId() {
        this.$refs.ArtFindCtrl.cachedItems = [];
        this.loadItems();
      },
      perIdTar() {
        this.$refs.ArtFindCtrl.cachedItems = [];
        this.loadItems();
      },
      value() {
        if (this.value != null) {
          this.loadItems();
        }
      }
    },
    methods: {
      loadItems(val) {
        var controllerParameters = {   
            Action: 'GET_DATA_LIST_PAGINATED',
            ArtNom: val,
            NumRegsPag: 35,
            NumPag: 1,
            EmpId: this.empId,
            ArtCatId: null,
            OpeId: this.opeId,
            PerIdTar: this.perIdTar,
            IncludeArtCat: this.includeArtCat,
            Searcher: true
        };       

        var valorActual = this.value;
        var AuthToken = localStorage.getItem('token');  
        axios({ method: "POST", "url": this.urlRaiz + "/api/art", "data": JSON.stringify(controllerParameters), "headers": {"content-type": "application/json", "Authorization": AuthToken } })
        .then(result => {
            this.itemsList = [];
            result.data.EntsList.forEach(element => {
              var itemObject = { ArtId: element.ArtId, ArtNom: element.ArtNom };
              if (this.includeArtCat) {
                itemObject.ArtCatArt = element.ArtCatArt;
              }
              this.itemsList.push(itemObject);
            });

            if (valorActual != null) {
              // if (valorActual.length == null) {
              if (!Array.isArray(valorActual)) {  // es un objeto
                if (valorActual.ArtId != null) {
                  if (!this.itemsList.some(itm=>itm.ArtId === valorActual.ArtId)) {
                    this.itemsList.push(valorActual);
                  }
                }
              }
              else {
                valorActual.forEach(element => {  // es un array
                  if (!this.itemsList.some(itm=>itm.ArtId === element.ArtId)) {
                    this.itemsList.push(element);
                  }
                });
              }
            }
        });
      },
      manageMenu(checkMenu) {
        if (checkMenu) {
          if (!this.$refs.ArtFindCtrl.isMenuActive) {
            this.$refs.ArtFindCtrl.isMenuActive = true;
          }
          else {
            this.$refs.ArtFindCtrl.isMenuActive = false;
          }
        }
      },
      clickEvt() {
        const checkMenu = this.readonly && this.selectOnReadonly;
        this.manageMenu(checkMenu);
        this.$emit('click');
      },
      clickAppendEvt() {
        const checkMenu = !this.readonly || (this.readonly && this.selectOnReadonly);
        this.manageMenu(checkMenu);
        this.$refs.ArtFindCtrl.focus();
        this.$emit('click-append');
      },
      inputEvt() {
        this.$emit('input', this.value);
      },
      changeEvt() {
        this.$emit('change', this.value);
      },
      blurEvt() {
        this.$emit('blur', this.value);
      },
      UpdateSearchInputEvt() {
        this.$emit('update:search-input', this.value);
      },
    },
    mounted() {
      // this.selectedValue = [];
      this.itemsList = [];
      this.loadItems();
    }
  }
</script>